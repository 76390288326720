import { createContext, useContext, useEffect, useState } from 'react'
import {  onAuthStateChanged } from "firebase/auth";
import { auth } from '../Firebase';
import axios from 'axios'

const AuthContext = createContext();

export function useAuth(){
    return useContext(AuthContext)
}

export function AuthProvider( {children} ){

    const [currentUser, setCurrentUser] = useState()
    const [isLoading, setIsLoading] = useState(true)
    const [venue, setVenue] = useState('all');

    const setupAxiosInterceptor = (user) => {
      axios.interceptors.response.use(
          response => response,
          async error => {
              // if the error is a 498 (unauthorized due expired token) and the error config allows retrying
              if (error.response && error.response.status === 498 && !error.config._retry) {
                  // mark the request for retry
                  error.config._retry = true

                  try {
                      const tokenResult = await user.getIdTokenResult(true)

                      // Update the request headers with the new token for the retry
                      error.config.headers['AuthToken'] = tokenResult.token
                      axios.defaults.headers.common['AuthToken'] = tokenResult.token

                      // and ensure user is up to date
                      setCurrentUser(user)

                      // Retry the failed request with the updated token
                      return axios(error.config)

                  } catch (refreshError) {
                      // handle token refresh error, possibly redirect to login or display error message
                      console.error('Failed to refresh token:', refreshError)
                  }
              }

              // If the error is not a 498 or token refresh fails, return the error
              return Promise.reject(error)
          }
      )
  }

   
  useEffect(() => {
    let unsubscribe = onAuthStateChanged(auth, (user) => {
        if (user) {

            // Retrieve the current valid token (force refresh)
            user.getIdToken(true).then((token) => {
                // Set the token in axios headers
                axios.defaults.headers.common['AuthToken'] = token

                setupAxiosInterceptor(user)

                // Get the user's ID token result with force refresh
                return user.getIdTokenResult(true); // Always force token refresh

            }).then(() => {
              
                setCurrentUser(user)
                setIsLoading(false)
            })
            .catch((error) => {
                setIsLoading(false)
                console.log("Error refreshing token", error)
            })
        } else {
            setCurrentUser(null)
            axios.defaults.headers.common['AuthToken'] = ""
            setIsLoading(false)
        }
    })

    return unsubscribe
}, [])


    const value = {
         currentUser, venue, setVenue
    }
    
    return (
        <AuthContext.Provider value={value}>
            {!isLoading && children}
        </AuthContext.Provider>

    )
}

