import React, { useState, useEffect} from 'react'
import { Link, Outlet, useLocation } from 'react-router-dom'
import './VenueUI.css'
import {  signOut } from "firebase/auth";
import { auth } from '../Firebase';
import { useAuth } from '../Auth/AuthContext'
import logo1 from '../Assets/logo1.png'
import logo2 from '../Assets/logo2.png'

export default function StudioUI() {
  let { currentUser} = useAuth()
  let location = useLocation();

  const [navOpen, setNavOpen] = useState(window.screen.width > 750 ? true : false)
  const [active, setActive] = useState('Dashboard')

  useEffect(() => {
    let url = location.pathname

    if(url === '/'){
      setActive("Dashboard")
    } else if(url.startsWith('/template')){
      setActive("Template")
    } else if(url.startsWith('/venues')){
      setActive("Venues")
    } else if(url.startsWith('/musicians')){
      setActive("Musicians")
    } else if(url.startsWith('/financials')){
      setActive("Financials")
    } else {
      setActive("")
    }

  }, [location])

    const handleLogout = () =>{
        signOut(auth).then(() => {
          // Sign-out successful.
        }).catch((error) => {
          // An error happened.
        });
      }
  return (
    <div>
        <header className={navOpen ?'business-nav' : 'business-nav-closed'}>
        <button onClick={() => setNavOpen(!navOpen)} id='toggle-nav-btn'>{navOpen ? `<` : `>`}</button>

          <div style={!navOpen ? {overflow:'hidden', transition: '0.5s'}: null}>

            <img src={logo1} style={{width: 70, marginLeft: '30%', marginBottom: 30}}/>
            <img src={logo2} style={{width: '90%', marginLeft: '5%', marginBottom: 30}}/>

            <nav>
              <p style={{fontFamily: 'Inter', fontSize: 11, color:'#111111', opacity: 0.5}}>MENU</p>
                <Link to={'/'} className={active === 'Dashboard' ? 'business-nav-item-active' : 'business-nav-item'} onClick={() => window.screen.width < 750 ? setNavOpen(false) : null}>
                  <img className={'nav-icon icon-filter'} src={require('../Assets/Icons/home.png')} alt='Home icon'/>
                  <p className={active === 'Dashboard' ? 'business-link-txt-active' : 'business-link-txt'}>Dashboard</p>
                </Link>
                <Link to={'template'} className={active === 'Template' ? 'business-nav-item-active' : 'business-nav-item'} onClick={() => window.screen.width < 750 ? setNavOpen(false) : null}>
                  <img className={'nav-icon icon-filter'} src={require('../Assets/Icons/jobs.png')} alt='Timetable icon'/>
                  <p className={active === 'Template' ? 'business-link-txt-active' : 'business-link-txt'}>Template</p>
                </Link>
                <Link to={'venues'} className={active === 'Venues' ? 'business-nav-item-active' : 'business-nav-item'} onClick={() => window.screen.width < 750 ? setNavOpen(false) : null}>
                  <img className={'nav-icon icon-filter'} src={require('../Assets/Icons/venue.png')} alt='Venues icon'/>
                  <p className={active === 'Venues' ? 'business-link-txt-active' : 'business-link-txt'}>Venues</p>
                </Link>
                <Link to={'musicians'} className={active === 'Musicians' ? 'business-nav-item-active' : 'business-nav-item'}onClick={() => window.screen.width < 750 ? setNavOpen(false) : null} >
                  <img className={'nav-icon icon-filter'} src={require('../Assets/Icons/applicants.png')} alt='Teachers icon'/>
                  <p className={active === 'Musicians' ? 'business-link-txt-active' : 'business-link-txt'}>Musicians</p>
                </Link>
                <Link to={'accounting'} className={active === 'Accounting' ? 'business-nav-item-active' : 'business-nav-item'} onClick={() => window.screen.width < 750 ? setNavOpen(false) : null}>
                  <img className={'nav-icon icon-filter'} src={require('../Assets/Icons/calculator.png')} alt='Wallet icon'/>
                  <p className={active === 'Financials' ? 'business-link-txt-active' : 'business-link-txt'}>Accounting</p>
                </Link>
              

                <button className='business-nav-item' id='business-logout-btn' onClick={handleLogout}>
                  <img className='nav-icon icon-filter' src={require('../Assets/Icons/logout.png')} alt='Logout icon'/>
                  <p className='business-link-txt'>Logout</p>
                </button>

            </nav>
            </div>

        </header>

        
        <Outlet context={{active, setActive, logo1, navOpen, setNavOpen}}/>
    </div>
  )
}
