import React, { useState, useEffect } from 'react'
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import './Artists.css'
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import axios from 'axios'
import { toast } from 'react-toastify'
import { createUserWithEmailAndPassword } from "firebase/auth";
import { artistAuth} from '../Firebase'
import ArtistTable from './ArtistTable';
import { useAuth } from '../Auth/AuthContext';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Checkbox from '@mui/material/Checkbox';
import { useOutletContext } from 'react-router-dom';

export default function Artists() {
  const { currentUser } = useAuth()

  const [open, setOpen] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [number, setNumber] = useState("");
  const [youtube, setYoutube] = useState("");
  const [drives, setDrives] = useState(false);
  const [pa, setPa] = useState(false);
  const [decks, setDecks] = useState(false);
  const [solo, setSolo] = useState(false);
  const [duo, setDuo] = useState(false);
  const [dj, setDj] = useState(false);


  const [artists, setArtists] = useState([])
  const [loading, setLoading] = useState(false)

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  useEffect(()=>{
    getArtists()
  },[])

  const getArtists = () =>{
    setLoading(true)
    axios.get(`${process.env.REACT_APP_API}/artist/get-artists`, {headers: {'AuthToken': currentUser.accessToken}})
    .then((res)=>{
      setArtists(res.data)
      setLoading(false)

    })
    .catch((e)=>{console.log(e);setLoading(false)})
  }

  const handleSave = () =>{
    if(!name || !email || !number){
      toast.error("Please add name, email and number")
    } else {

      createUserWithEmailAndPassword(artistAuth, email, "password")
        .then((userCredential) => {
          const user = userCredential.user;

          axios.post(`${process.env.REACT_APP_API}/artist/add-new`, {_id: user.uid, name, email, number, youtube, img:"https://firebasestorage.googleapis.com/v0/b/musicgoferproductionadmin.appspot.com/o/singer.png?alt=media&token=7213539a-58ca-4c9d-ab67-cd85a793edd2", drives, pa, decks, solo, duo, dj}, 
          {headers: {'AuthToken': currentUser.accessToken}})
          .then(()=>{
            toast.success("Successfully added")
            handleClose()
            emailArtist(email, name)
            getArtists()
            setName("");setEmail("");setNumber("");setYoutube("")
          })
          .catch((e)=>console.log(e))
        })
        .catch((error) => {
          const errorMessage = error.message;
          toast.error(errorMessage)
        });
      }
  }

  const emailArtist = (artistEmail, artistName) =>{
      axios.post(`${process.env.REACT_APP_API}/email/new-artist`, {email: artistEmail, name: artistName}, {headers: {'AuthToken': currentUser.accessToken}})
      .then(()=>{
        console.log("Email sent")
      })
      .catch((e)=>console.log(e))
    }

    const { navOpen } = useOutletContext();

  return (
    <main className={navOpen ? 'outlet' : 'outlet-expanded'} style={{display:'flex', alignItems:'center', flexDirection:'column'}}> 
      <Fab sx={{backgroundColor: '#3496d1', flexShrink: 0}} aria-label="add" onClick={handleOpen}>
        <AddIcon sx={{color: 'white'}} />
      </Fab>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Add New Artist
          </Typography>
          <TextField sx={{my:1}} fullWidth variant='outlined' label='Artist Name' value={name} onChange={(e)=>setName(e.target.value)}/>
          <TextField sx={{my:1}} fullWidth variant='outlined' label='Email' value={email} onChange={(e)=>setEmail(e.target.value)}/>
          <TextField sx={{my:1}} fullWidth variant='outlined' label='Phone Number (inc. +44)' value={number} onChange={(e)=>setNumber(e.target.value)}/>
          <TextField sx={{my:1}} fullWidth variant='outlined' label='Youtube Link' value={youtube} onChange={(e)=>setYoutube(e.target.value)}/>
          <FormGroup row sx={{my:1}}>
            <FormControlLabel control={<Switch checked={drives} onChange={()=> setDrives(!drives)} />} label="Drives"labelPlacement="start" />
            <FormControlLabel control={<Switch checked={pa} onChange={()=> setPa(!pa)} />} label="PA" labelPlacement="start"/>
            <FormControlLabel control={<Switch checked={decks} onChange={()=> setDecks(!decks)} />} label="Decks" labelPlacement="start"/>
          </FormGroup>
          <FormGroup row sx={{my:1}}>
            <FormControlLabel control={<Checkbox checked={solo} onChange={()=> setSolo(!solo)} />} label="Solo" labelPlacement="start"/>
            <FormControlLabel  control={<Checkbox checked={duo} onChange={()=> setDuo(!duo)}/>} label="Duo" labelPlacement="start"/>
            <FormControlLabel  control={<Checkbox checked={dj} onChange={()=> setDj(!dj)}/>} label="DJ" labelPlacement="start"/>
          </FormGroup>
          <Button sx={{backgroundColor: '#3496d1'}} variant='contained' onClick={handleSave}>Save</Button>
        </Box>
      </Modal>

      <div>
        <h3>Artists</h3>
        {loading ? 
        <Box sx={{ display: 'flex' }}>
          <CircularProgress />
        </Box>: 
        artists.length < 1 ?
        <p>No artists added</p> :
       <ArtistTable artists={artists} getArtists={getArtists}/>}
      </div>
    </main>
  )
}

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  maxWidth: '90%',
  maxHeight: '80%',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  overflow:'scroll',
};
