import React, { useState, useEffect } from 'react';
import './Template.css';
import axios from 'axios';
import bin from '../Assets/Icons/bin.png';

export default function Template() {
    const [templates, setTemplates] = useState([]);
    const [venues, setVenues] = useState([]);
    const [open, setOpen] = useState(false);
    const [selectedDay, setSelectedDay] = useState("")
    const [eventType, setEventType] = useState("")
    const [venueId, setVenueId] = useState("")
    const [venueName, setVenueName] = useState("")
    const [setTimes, setSetTimes] = useState([])


    useEffect(() => {
        getTemplates()
        getVenues()
    }, [])

    useEffect(() => {
        if(venueId){
            let venue = venues.find((ven) => ven._id === venueId)
            setVenueName(venue.venueName)
            setSetTimes(venue.setTimes)
          
        }

    }, [venueId])

    const getVenues = () => {
        axios.get(`${process.env.REACT_APP_API}/venue/get-venues`)
        .then((res) => {
            setVenues(res.data)
            console.log(res.data)
        })
        .catch((e) => {
            console.log(e)
        })
    }

    const getTemplates = () => {
        axios.get(`${process.env.REACT_APP_API}/template/get-templates`)
        .then((res) => {
            setTemplates(res.data)
            console.log(res.data)
        })
        .catch((e) => {
            console.log(e)
        })
    }

    // Days array reordered for Monday-Sunday display
    const days = [
        { label: 'Monday', index: 1 },
        { label: 'Tuesday', index: 2 },
        { label: 'Wednesday', index: 3 },
        { label: 'Thursday', index: 4 },
        { label: 'Friday', index: 5 },
        { label: 'Saturday', index: 6 },
        { label: 'Sunday', index: 0 },
    ];

    const handleSave = () => {
        let payload = {
            day: selectedDay,
            eventType,
            venueName,
            venueId,
            setTimes
        }
        if(!venueId || !eventType || setTimes.length < 1){
            return
        } else {
            axios.post(`${process.env.REACT_APP_API}/template/add-template`, payload)
            .then(() => {
                setOpen(false)
                setEventType("")
                setVenueId("")
                getTemplates()
                setSetTimes([])
            })
            .catch((e) => {
                console.log(e)
            })
        }

    }

    const handleDelete = (id) => {
        if(window.confirm("Are you sure you want to delete?")){
            axios.post(`${process.env.REACT_APP_API}/template/delete`, {id})
            .then(() => {
                getTemplates()
            })
            .catch((e) =>{
                console.log(e)
            })
        } else {
            return
        }
    }

    const deleteSet = (index) => {
        let newSets = setTimes.filter((_, i) => i !== index)
        setSetTimes(newSets)
    }

    return (
        <div>
            <table id='template-table'>
                <thead>
                    <tr>
                        {days.map((day) => (
                            <th key={day.index}>{day.label}</th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        {days.map((day) => (
                                     <td key={day.index}>
                                     <div className="shift-container">
                                         {/* Filter and display events for the current day */}
                                         {templates
                                             ?.filter((gig) => gig.day === day.index) // Only render events for the current day
                                             .map((gig, idx) => (
                                                 <div key={idx} className="gig">
                                                    <img 
                                                        src={bin} 
                                                        className="delete-gig" 
                                                        alt="Music Gofer delete bar or restaurant live music"
                                                        onClick={() => handleDelete(gig._id)}
                                                    />
                                                     <strong>{gig.venueName} ({gig.eventType})</strong>
                                                     <p>
                                                         {gig.setTimes[0]?.from} - {gig.setTimes[gig.setTimes.length -1]?.to}
                                                     </p>
                                                 </div>
                                             ))}
     
                                         <button
                                             className="add-button"
                                             onClick={() => {
                                                 setOpen(true);
                                                 setSelectedDay(day.index);
                                             }}
                                         >
                                             + Add Gig
                                         </button>
                                     </div>
                                 </td>
                        ))}
                    </tr>
                </tbody>
            </table>

            {open &&
                <div className='modal'>
                    <h2>Add gig to template</h2>
                    <select className='modal-select' value={venueId} onChange={(e) => setVenueId(e.target.value)}>
                        <option value="" disabled>Select Venue</option>
                        {venues.map((venue, i) => {
                            return (
                                <option key={i} value={venue._id}>{venue.venueName}</option>
                            )
                        })}
                    </select>

                    <select className='modal-select' value={eventType} onChange={(e) => setEventType(e.target.value)}>
                        <option value="" disabled>Select Act Type</option>
                        <option value="Solo">Solo</option>
                        <option value="Duo">Duo</option>
                        <option value="DJ">DJ</option>
                        <option value="Band">Band</option>
                    </select>

                    {setTimes.map((timings, i) => {
                        return (
                            <div key={i} style={{display:'flex', justifyContent:'space-between', alignItems:'center'}}>
                                <p>{timings.from} - {timings.to}</p>
                                <img src={bin} onClick={() => deleteSet(i)} style={{height: 20, width: 20}} alt='Delete set time'/>
                            </div>
                        )
                    })}
                    <div>
                        <button className='save-btn' onClick={handleSave}>Save</button>
                        <button className='close-btn' onClick={() => setOpen(false)}>Close</button>
                    </div>
                  
                </div>
            }
        </div>
    );
}
