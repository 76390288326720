import React, {useEffect} from 'react'
import { useParams } from 'react-router-dom'
import axios from 'axios'
import logo from '../Assets/logo1.png'

export default function ConfirmGig() {

    let {id} = useParams();

    useEffect(()=>{
        confirmGig()
    },[])

    const confirmGig = () =>{
        axios.post(`${process.env.REACT_APP_API}/confirm-by-email`, {id})
        .then((res)=>{
            console.log(res.data)
        })
        .catch((e)=>{console.log(e)})
    }

  return (
    <div style={{display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center', paddingTop:'4rem'}}>
        <img src={logo} style={{filter:'invert(1)', width:'250px', margin:'2rem'}}/>
        <h3>Thanks for confirming the gig!</h3>
        <p>Please use the Music Gofer app to get all the gig requirements.</p>
    </div>
  )
}
