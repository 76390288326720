import React, { useState, useEffect} from 'react'
import './AdminUI.css'
import DescriptionIcon from '@mui/icons-material/Description';
import DownloadIcon from '@mui/icons-material/Download';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { toast } from 'react-toastify'
import axios from 'axios'
import { CSVLink } from "react-csv";
import moment from 'moment-timezone'
import { useAuth } from '../Auth/AuthContext';
import OutboxIcon from '@mui/icons-material/Outbox';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { border, borderRadius } from '@mui/system';

export default function Accounting() {

    const { currentUser} = useAuth();
    //Data modal
      const [openModal, setOpenModal] = useState(false);
      const handleOpen = () => setOpenModal(true);
      const handleClose = () =>{ setOpenModal(false);setFrom("");setTo("");setData([])};
       //Invoice modal
       const [openInvoice, setOpenInvoice] = useState(false);
       const handleOpenInvoice = () => setOpenInvoice(true);
       const handleCloseInvoice = () => setOpenInvoice(false);
      //Venue modal
      const [openVenue, setOpenVenue] = useState(false);
      const handleOpenVenue = () => setOpenVenue(true);
      const handleCloseVenue = () =>{ setOpenVenue(false);setVenue("");setVenueFrom("");setVenueTo("");setVenueData("")};
    
      const [loading, setLoading] = useState(false)
      const [from, setFrom] = useState("")
      const [to, setTo] = useState("")
      const [data, setData] = useState("")
      const [invoiceFrom, setInvoiceFrom] = useState("")
      const [invoiceTo, setInvoiceTo] = useState("")
      const [invoiceData, setInvoiceData] = useState("")
      const [dueDate, setDueDate] = useState("")
      const [venue, setVenue] = useState('');
      const [venues, setVenues] = useState([]);
      const [venueData, setVenueData] = useState('');
      const [venueFrom, setVenueFrom] = useState("")
      const [venueTo, setVenueTo] = useState("")
      const [contactEmail, setContactEmail] = useState("")
  
      useEffect(()=>{
        getVenues()
      },[])
    
      const getVenues = () =>{
        setLoading(true)
        axios.get(`${process.env.REACT_APP_API}/venue/get-venues`, {headers: {'AuthToken': currentUser.accessToken}})
        .then((res)=>{setVenues(res.data);setLoading(false)})
        .catch((e)=>{console.log(e);setLoading(false)})
    
      }
    
  
      useEffect(()=>{
          if(to && from){
              handleDownload()
          }
      },[to])
  
      useEffect(()=>{
        if(invoiceTo && invoiceFrom){
            getInvoices()
        } else return
    },[invoiceTo, invoiceFrom])
  
    useEffect(()=>{
      if(venueFrom && venueTo && venue){
        axios.post(`${process.env.REACT_APP_API}/venue/get-venue-listings`,{venueId: venue, from: venueFrom, to: venueTo}, {headers: {'AuthToken': currentUser.accessToken}})
        .then((res)=>{setVenueData(res.data);console.log(res.data)})
        .catch((e)=>{console.log(e);})
      }
  },[venueFrom, venueTo, venue])
  
  useEffect(()=>{
    if(venue){
      getInfo(venue)
    }
  
  },[venue])
  
  const getInfo = (venueId) =>{
    let venueInfo = venues.filter((venue)=> venue._id === venueId)
    console.log("VEnue", venueInfo)
    setContactEmail(venueInfo[0].contactEmail)
  }
     
  
      const handleDownload = (event, done) =>{
        if(from > to){
            return toast.error("The from date must be before the to date")
        } else {
  
           
            setLoading(true)
            
  
            axios.post(`${process.env.REACT_APP_API}/event/get-data`,{from, to}, {headers: {'AuthToken': currentUser.accessToken}})
            .then((res)=>{
                let formatted = res.data.map(obj => {
                    return {...obj, eventDate: new Date(obj.eventDate)};
                  });
                  console.log("Formatted",formatted)
                const sorted = formatted.sort(
                    (objA, objB) =>   Number(objA.eventDate)-Number(objB.eventDate)
                  );
                let finalData = sorted.map((event)=>{
                    const { title, artistName, eventDate, venueFee, artistFee} = event;
                    return (
                        {title: title, artistName, eventDate: moment(eventDate).tz("Europe/London").format('DD/MM/YY'), venueFee: venueFee?venueFee.toString():"", artistFee: artistFee?artistFee.toString():""}
                    )
                })
                console.log("Formatted",formatted, "Sprted",sorted, finalData)
  
             
                setData(finalData)
                setLoading(false)
                
            })
            .catch((e)=>{console.log(e);setLoading(false)
            })
        }
    }
  
    const getInvoices = (event, done) =>{
      if(invoiceFrom > invoiceTo){
          toast.error("The from date must be before the to date")
      } else {
         
          setLoading(true)
          
          axios.post(`${process.env.REACT_APP_API}/event/get-invoice-data`,{invoiceFrom, invoiceTo}, {headers: {'AuthToken': currentUser.accessToken}})
          .then((res)=>{
            console.log(res.data)
              let formatted = res.data.map(obj => {
                  return {...obj, start: new Date(obj.start)};
                });
              const sorted = formatted.sort(
                  (objA, objB) =>   Number(objA.start)-Number(objB.start)
                );
              let finalData = sorted.map((event)=>{
                  const { start, venueFee, xeroName} = event;
                  return (
                      {ContactName: xeroName, InvoiceNumber:`INV-001`, InvoiceDate: moment().format('DD/MM/YYYY'), DueDate: moment(dueDate).format('DD/MM/YYYY'), InventoryItemCode: "LM001", Description: `Live Music - ${moment(start).format('DD MMM YYYY')}`, Quantity:1 , UnitAmount: venueFee.toString(), AccountCode: 200, TaxType:"20% (VAT on Income)", Currency: "GBP"}
                  )
              })
           
              console.log(finalData)
              setInvoiceData(finalData)
              setLoading(false)
              
          })
          .catch((e)=>{console.log(e);setLoading(false)
          })
      }
  }
  
        const headers = [
          { label: "Date", key: "eventDate" },
          { label: "Artist", key: "artistName" },
          { label: "Venue", key: "title" },
          { label: "Artist Fee", key: "artistFee" },
          { label: "Venue Fee", key: "venueFee" }
        
        ];
        const invoiceHeaders = [
          { label: "ContactName", key: "ContactName" },
          { label: "InvoiceNumber", key: "InvoiceNumber" },
          { label: "InvoiceDate", key: "InvoiceDate" },
          { label: "DueDate", key: "DueDate" },
          { label: "InventoryItemCode", key: "InventoryItemCode" },
          { label: "Description", key: "Description" },
          { label: "Quantity", key: "Quantity" },
          { label: "UnitAmount", key: "UnitAmount" },
          { label: "AccountCode", key: "AccountCode" },
          { label: "TaxType", key: "TaxType" },
          { label: "Currency", key: "Currency" }
        ];
       
  
        const emailVenue = () =>{
          if(venueData.length < 1){
            toast.error("No Data")
          } else if(!contactEmail){
            toast.error("No Contact Email")
          } else {
            axios.post(`${process.env.REACT_APP_API}/email/venue-listings`,{data: venueData, contactEmail}, {headers: {'AuthToken': currentUser.accessToken}})
            .then((res)=>{
                console.log(res.data)
                handleCloseVenue()
            })
            .catch((e)=>{console.log(e)
            })
          }
          
        }
  
  return (
    <div className='outlet'>
        <div style={{padding: '2rem'}}>
            <h2>Accounts</h2>
            <button onClick={handleOpenVenue} style={accountingBtn}>
                <OutboxIcon sx={{color: '#fff'}}/>
                <p className={'nav-title'}>Share Data</p>
            </button>
            <button onClick={handleOpen} style={accountingBtn}>
                <DownloadIcon sx={{color: '#fff'}}/>
                <p className={'nav-title'}>Download Data</p>
            </button>
            <button onClick={handleOpenInvoice} style={accountingBtn}>
                <DescriptionIcon sx={{color: '#fff'}}/>
                <p className={'nav-title'}>Invoice Data</p>
            </button>

        </div>
        <Modal
        open={openVenue}
        onClose={handleCloseVenue}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Send Venue Data
          </Typography>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Venue</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={venue}
              label="Venue"
              onChange={(e)=>setVenue(e.target.value)}
            >
              <MenuItem value={""}>Select Venue</MenuItem>
              {venues.map((venue, i)=>{
                return <MenuItem key={i} value={venue._id}>{venue.venueName}</MenuItem>
              })}
            </Select>
          </FormControl>
           <div >
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              Select date range:
            </Typography>
            <div>
              <input type='date' value={venueFrom} onChange={(e)=>setVenueFrom(e.target.value)}/> <span> - </span>
              <input type='date' value={venueTo} onChange={(e)=>setVenueTo(e.target.value)}/>
            </div>
           
              {venueData &&
               <Button  variant='contained' sx={{my:2, backgroundColor:'#395076'}} disabled={!venueData} onClick={emailVenue}>
                       Send 
               </Button>
              }
            </div>
        </Box>
      </Modal>

        <Modal open={openModal} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Download Events
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Select date range:
          </Typography>
          <div>
            <input type='date' value={from} onChange={(e)=>setFrom(e.target.value)}/> <span> - </span>
            <input type='date' value={to} onChange={(e)=>setTo(e.target.value)}/>
          </div>
          < CSVLink filename={"music-gofer.csv"}  data={data} headers={headers} disabled={loading || !data}>
            <Button  variant='contained' sx={{my:2, backgroundColor:'#395076'}} disabled={loading || !data}>
                    Download 
            </Button>
            </CSVLink>
        </Box>
      </Modal>

      <Modal
        open={openInvoice}
        onClose={handleCloseInvoice}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Download Invoice Data
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Select invoice due date:
          </Typography>
          <input type='date' value={dueDate} onChange={(e)=>setDueDate(e.target.value)}/> 
          {dueDate && <div >
            <Typography id="modal-modal-description" sx={{ mt: 2 }}>
              Select date range:
            </Typography>
            <div>
              <input type='date' value={invoiceFrom} onChange={(e)=>setInvoiceFrom(e.target.value)}/> <span> - </span>
              <input type='date' value={invoiceTo} onChange={(e)=>setInvoiceTo(e.target.value)}/>
            </div>
            
            < CSVLink filename={"music-gofer.csv"}  data={invoiceData} headers={invoiceHeaders} >
              <Button  variant='contained' sx={{my:2, backgroundColor:'#395076'}} disabled={loading || !invoiceData}>
                      Download 
              </Button>
              </CSVLink>
            </div>}

        </Box>
      </Modal>

    </div>
  )
}
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    maxWidth: '90%',
    maxHeight: '80%',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    textAlign: 'center',
    overflow: 'scroll'
  };

  const accountingBtn = {
    backgroundColor: '#f47fdf',
    marginBottom: 10,
    marginRight: 10,
    display: 'flex',
    alignItems: 'center',
    border: 'unset',
    padding: '10px 20px',
    borderRadius: 3
  }