import React, { useState } from 'react'
import Template from './Template'
import DraftCalendar from './DraftCalendar'

export default function EventManager() {

    const [tab, setTab] = useState(1)

  return (
    <div className='outlet' style={{padding: '2rem'}}>
        <div className='tabs'>
            <button className={tab===1 ? 'tab-active': ''} onClick={() => setTab(1)}>
                Template
            </button>
            <button className={tab===2 ? 'tab-active': ''} onClick={() => setTab(2)}>
                Drafts
            </button>
        </div>

        {tab === 1 &&
            <Template />
        }

        {tab === 2 &&
            <DraftCalendar />
        }

        
    </div>
  )
}
