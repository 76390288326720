import React, { useState, useEffect, useRef } from 'react'
import FullCalendar from '@fullcalendar/react' // must go before plugins
import dayGridPlugin from '@fullcalendar/daygrid' // a plugin!
import listPlugin from '@fullcalendar/list';
import moment from 'moment'
import axios from 'axios'
import { toast } from 'react-toastify'

export default function DraftCalendar() {
    const calendarRef = useRef(null); 

    const [events, setEvents] = useState([])
    const [startOfMonth, setStartOfMonth] = useState(""); 
    const [endOfMonth, setEndOfMonth] = useState(""); 
    const [generating, setGenerating] = useState(false); 

    useEffect(() => {
        if(startOfMonth && endOfMonth){
            getDrafts()
        }
    }, [startOfMonth, endOfMonth])

    const getDrafts = () => {
        axios.post(`${process.env.REACT_APP_API}/template/get-draft-events-by-date-range`, {startOfMonth, endOfMonth})
        .then((res) => {
            setEvents(res.data)
        })
        .catch((e) => {
            console.log(e)
        })
    }

    async function generateMonthEvents() {

        setGenerating(true)
        const startDate = moment(startOfMonth).format("YYYY-MM-DD");
        const endDate = moment(endOfMonth).format("YYYY-MM-DD");
      
        axios.post(
            `${process.env.REACT_APP_API}/template/generate-month`,
            { startDate, endDate },
        )
        .then(() => {
            getDrafts()
        })
        .catch((e) => {
            console.error("Error generating month events:", e);
        })
        .finally(() => {
            setGenerating(false)
        })
      }

      const publish = () => {
        axios.post(`${process.env.REACT_APP_API}/template/publish`, { events})
        .then(() => {
            toast.success("Published")
            getDrafts()
        })
        .catch((e) => {
            toast.error("Error publishing")
        })

      }

      const clearDraft = () => {
        if(window.confirm("Are you sure you want to delete?")){
            axios.post(`${process.env.REACT_APP_API}/template/delete-draft`, { events})
            .then(() => {
                getDrafts()
            })
            .catch((e) => {
                toast.error("Error publishing")
            })
        } else {
            return
        }
       
      }

      const handleDatesSet = () => {
        const calendarApi = calendarRef?.current?.getApi(); // Get FullCalendar instance
        const calendarDate = calendarApi?.getDate(); // Get the exact current date of the view
    
        const start = moment(calendarDate).startOf('month');
        const end = moment(calendarDate).endOf('month');

        setStartOfMonth(start.startOf('day'));
        setEndOfMonth(end.endOf('day'));
      };

  return (
    <div>
        {events.length < 1 ?
            <button style={{margin: '10px 0'}} onClick={generateMonthEvents} disabled={generating}>
                Generate Month
            </button>
            :
            <div>
            <button style={{margin: '10px'}} onClick={publish}>
                Publish
            </button>
            <button style={{margin: '10px'}} onClick={clearDraft}>
                Delete Draft
            </button>
            </div>
        }       

        <FullCalendar
                ref={calendarRef} // Attach the ref to the calendar
                plugins={[ dayGridPlugin, listPlugin ]}
                nextDayThreshold='09:00:00'
                initialView="dayGridMonth"
                headerToolbar={{center:'dayGridMonth,listMonth'}}
                eventSources={[events]}
                firstDay={1}
                displayEventTime={false}
                eventContent={function( info ) {
                  return {html: `${moment(info.event.start).format("H:mm a")} ${info.event.title}`};
                }} 
                eventTimeFormat={{
                    hour: 'numeric',
                    minute: '2-digit',
                    omitZeroMinute: true,
                    meridiem: 'short'
                }}
                eventClassNames={'draft-event'}
                datesSet={(info) =>handleDatesSet(info)} // Callback for visible range changes

            />
    </div>
  )
}
