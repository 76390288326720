import React, {useState, useEffect, useRef} from 'react'
import FullCalendar from '@fullcalendar/react' // must go before plugins
import dayGridPlugin from '@fullcalendar/daygrid' // a plugin!
import listPlugin from '@fullcalendar/list';
import axios from 'axios'
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField'
import { toast } from 'react-toastify'
import Chip from '@mui/material/Chip';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { useAuth } from '../Auth/AuthContext';
import moment from 'moment-timezone'
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import '../App.css'

export default function Calendar() {

  const calendarRef = useRef(null); 
  const { currentUser, venue, setVenue} = useAuth();

  const [events, setEvents] = useState([])
  const [filteredEvents, setFilteredEvents] = useState([])
  const [applied, setApplied] = useState([])
  const [title, setTitle] = useState("")
  const [eventType, setEventType] = useState("")
  const [artistFee, setArtistFee] = useState("")
  const [venueFee, setVenueFee] = useState("")
  const [notes, setNotes] = useState("")
  const [setTimes, setSetTimes] = useState("")
  const [equipment, setEquipment] = useState("")
  const [id, setId] = useState("")
  const [newItem, setNewItem] = useState("")
  const [from, setFrom] = useState("");
  const [to, setTo] = useState("");
  const [date, setDate] = useState("")
  const [eventDate, setEventDate] = useState("")
  const [startOfMonth, setStartOfMonth] = useState(""); 
  const [endOfMonth, setEndOfMonth] = useState(""); 
  const [artistObj, setArtistObj] = useState("")
  const [open, setOpen] = useState(false);
  const [venues, setVenues] = useState([]);
//Artist select
const [artists, setArtists] = useState([])
const [artist, setArtist] = useState("")
const [artistName, setArtistName] = useState("")
const [artistEmail, setArtistEmail] = useState("")
const [artistNumber, setArtistNumber] = useState("")
const [artistImg, setArtistImg] = useState("")
//stats
const [turnover, setTurnover] = useState(0)
const [cogs, setCogs] = useState(0)

  const handleChange = (event) => {
    setVenue(event.target.value );
  };

  const handleClose = () =>{ 
    setOpen(false);
    setArtist("");
    setSetTimes([]);
    setEquipment([]);
    setNotes("");
    setArtistFee("");
    setVenueFee("");
    setEventType("")
}

  useEffect(() => {
    if(startOfMonth && endOfMonth){
        getEvents()
    }
  }, [startOfMonth, endOfMonth])

  const getEvents = () => {
    axios.post(`${process.env.REACT_APP_API}/event/get-events-by-date-range`, {startOfMonth, endOfMonth})
    .then((res) => {
        setEvents(res.data)
    })
    .catch((e) => {
        console.log(e)
    })
  }

  useEffect(() => {
    if(!artist){
      setArtistName("");
      setArtistEmail("");
      setArtistNumber("")
      setArtistImg("")
    } else {
      axios.post(`${process.env.REACT_APP_API}/artist/get-artist`, { _id: artist }, {headers: {'AuthToken': currentUser.accessToken}})
      .then((res)=>{
        let { email, img, name, number } = res.data;
        setArtistObj(res.data)
        setArtistName(name);
        setArtistEmail(email);
        setArtistNumber(number)
        setArtistImg(img)
      })
      .catch((e)=>{
        console.log(e)
      })
    }
  }, [artist])

  useEffect(()=>{
    getEvents();
    getArtists();
    getVenues();
  },[])

  useEffect(()=>{

    //Negative profit gigs
    let cashGigs = events.filter((gig)=> gig.venueFee < gig.artistFee)
    let cashTurnover = cashGigs.reduce((accumulator, currentValue)=> accumulator + currentValue.venueFee + currentValue.artistFee, 0)

    //Positive profit gig
    let invoiceGigs = events.filter((gig)=> gig.venueFee >= gig.artistFee)
    let invoiceTurnover = invoiceGigs.reduce((accumulator, currentValue)=> accumulator + currentValue.venueFee, 0)

    //Artist fees
    let cogs = events.reduce((accumulator, currentValue)=> accumulator + currentValue.artistFee, 0)
    
    setTurnover(cashTurnover+invoiceTurnover)
    setCogs(cogs)

  },[ events])

  useEffect(()=>{
    if(venue == "all"){
      setFilteredEvents(events)
    } else {
      let filtered = events.filter((choice)=> choice.venueId == venue)
      setFilteredEvents(filtered)
    }

  },[venue, events])

  const getVenues = () =>{
    axios.get(`${process.env.REACT_APP_API}/venue/get-venues`, {headers: {'AuthToken': currentUser.accessToken}})
    .then((res)=>{setVenues(res.data)})
    .catch((e)=>{console.log(e);})

  }

  const getArtists = () =>{
    axios.get(`${process.env.REACT_APP_API}/artist/get-artists`, {headers: {'AuthToken': currentUser.accessToken}})
    .then((res)=>{
      let sorted = res.data.sort((a, b) => a.name !== b.name ? a.name < b.name ? -1 : 1 : 0)

      setArtists(sorted)
    })
    .catch((e)=>console.log(e))
  }

  const handleChipDelete = (itemToDelete) => () => {
    setEquipment((item) => item.filter((item) => item.key !== itemToDelete.key));
  };
  const addItem = (e)=>{
    if(e.key === 'Enter'){
      console.log(newItem)
      setEquipment([...equipment, {key: equipment[equipment.length-1].key+1, label: newItem}])
      setNewItem("")
    }
  }
  const removeSet = (set) =>{
    setSetTimes((item) => item.filter((item) => item.key !== set.key));
  } 

  const addSet = () =>{
    if(!from || !to){
      toast.error("Please add from and to time")
    } else {
      let key = setTimes.length < 1 ? 1 : setTimes[setTimes.length-1].key+1
      setSetTimes([...setTimes, {key,from, to}])
      setTo("");setFrom("")
    }
  }

  const handleClick = (info) =>{
    const {title} = info.event;
    const { artistId, setTimes, equipment, artistFee, venueFee, notes, _id, applied, eventType, dateStr} = info.event.extendedProps;
    let artistsGigging = events.filter((e)=> e.dateStr === dateStr ).map((e)=> e.artistId)
    let removed = applied.filter((appliedArtist)=> !artistsGigging.includes(appliedArtist))
    setOpen(true); setEventDate(dateStr)
    setDate(moment(info.event.start).format('YYYY-MM-DD'))
    setTitle(title);setArtistFee(artistFee);setVenueFee(venueFee);setNotes(notes);setSetTimes(setTimes);setEquipment(equipment);setId(_id);setArtist(artistId); setApplied(removed); setEventType(eventType)
  }

  const deleteEvent = () =>{
    if(window.confirm("Are you sure you want to delete event?")){
      axios.post(`${process.env.REACT_APP_API}/event/delete`, {_id: id}, {headers: {'AuthToken': currentUser.accessToken}})
      .then((res)=>{
        toast.success("Successfully deleted")
        handleClose();getEvents();
      })
      .catch((e)=>console.log(e))
  }
  }

 const updateEvent = () =>{
  axios.post(`${process.env.REACT_APP_API}/event/update`, 
    {
      _id: id, 
      payload:
        {
          start: moment(`${date}T${setTimes[0].from}`).tz("Europe/London").format(), 
          artistFee, 
          artistImg, 
          venueFee, 
          setTimes, 
          equipment, 
          eventType, 
          notes, 
          artistId: artist, 
          artistName, 
          artistEmail, 
          artistNumber,
          artist: artistObj
        }
    }, 
  {headers: {'AuthToken': currentUser.accessToken}})
  .then((res)=>{
    toast.success("Successfully updated")
    handleClose();getEvents();
  })
  .catch((e)=>console.log(e))
  }

  const checkArtist = () =>{
    if(artist){
      axios.post(`${process.env.REACT_APP_API}/event/check-artist`, {artistId: artist, date: eventDate, eventId: id}, {headers: {'AuthToken': currentUser.accessToken}})
      .then((res)=>{
        if(res.data.length < 1){
          updateEvent()
        } else {
          if(window.confirm("Artist is already gigging, are you sure you want to add them?")){
            updateEvent()
          }
        }
      })
      .catch((e)=>console.log(e))
    } else {
      updateEvent()
    }
  }

  useEffect( ()=>{
    if(artist){
    let info = getInfo(artist)
      setArtistName(info[0].name);setArtistEmail(info[0].email);setArtistNumber(info[0].number);setArtistImg(info[0].img)  }
  },[artist])

  const getInfo = (id) =>{
    let info = artists.filter(choice=> choice._id === id )
    return info
  }

  const handleDatesSet = () => {
      const calendarApi = calendarRef?.current?.getApi(); // Get FullCalendar instance
      const calendarDate = calendarApi?.getDate(); // Get the exact current date of the view
      
      const start = moment(calendarDate).startOf('month');
      const end = moment(calendarDate).endOf('month');
  
      setStartOfMonth(start.startOf('day'));
      setEndOfMonth(end.endOf('day'));
  };
  

  return (
    <div >
      <div style={{ minWidth: 120, display:'flex', justifyContent: 'space-between', flexWrap:'wrap', marginBottom: '1rem' }}>
      <FormControl sx={{minWidth: 120, width: 200}}>
        <InputLabel id="demo-simple-select-label">Venues</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={venue}
          label="Venues"
          onChange={handleChange}
        >
          <MenuItem value="all">All</MenuItem>
          {venues.map((venue, i)=>{
            return <MenuItem key={i} value={venue._id}>{venue.venueName}</MenuItem>

          })}
        </Select>
      </FormControl>
        <table id='stats-table'>
          <thead>
            <tr>
              <th>Turnover</th>
              <th>Net profit</th>
              <th>No. of gigs</th>
            </tr>
          </thead>
          <tbody>
            <td>£{turnover}</td>
            <td>£{turnover - cogs}</td>
            <td>{events.length}</td>
          </tbody>
        </table>
      
    </div>
  
        <FullCalendar
                ref={calendarRef} // Attach the ref to the calendar
                plugins={[ dayGridPlugin, listPlugin ]}
                nextDayThreshold='09:00:00'
                initialView="dayGridMonth"
                headerToolbar={{center:'dayGridMonth,listMonth'}}
                eventSources={[venue == "all" ? events : filteredEvents]}
                eventClick={function(info){handleClick(info)}}
                contentHeight={'100vh'}
                firstDay={1}
                displayEventTime={false}
                datesSet={(info) =>handleDatesSet(info)} // Callback for visible range changes

                eventContent={function( info ) {
                  let name = info.event.extendedProps.artistName ? info.event.extendedProps.artistName : ""
                  return {html: `${moment(info.event.start).format("H:mm a")} ${info.event.title} - ${name}`};
              }} 
              eventTimeFormat={{
                hour: 'numeric',
                minute: '2-digit',
                omitZeroMinute: true,
                meridiem: 'short'
              }}
              eventClassNames={(event) => {
                // Define conditional class logic here
                if (event.event.extendedProps.status === 'Confirmed') {
                  return 'confirmed-event';
                } else if (event.event.extendedProps.status === 'Pending') {
                  return 'pending-event';
                } else if (event.event.extendedProps.applied.length > 0 ) {
                  return 'unassigned-event';
                }
                // Default class name if none of the conditions are met
                return 'default-event';
              }}

            />

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {title} - {eventType}
          </Typography>

          <FormControl sx={{mb:2}}>
          <RadioGroup row aria-labelledby="demo-row-radio-buttons-group-label" name="eventType" value={eventType} onChange={(e)=>setEventType(e.target.value)}>
            <FormControlLabel value="Solo" control={<Radio />} label="Solo" />
            <FormControlLabel value="Duo" control={<Radio />} label="Duo" />
            <FormControlLabel value="DJ" control={<Radio />} label="DJ" />
            <FormControlLabel value="Band" control={<Radio />} label="Band" />
          </RadioGroup>
        </FormControl>

      
          <FormControl fullWidth sx={{my:1}}>
            <InputLabel id="demo-simple-select-label">Artist</InputLabel>
            <Select  labelId="demo-simple-select-label" id="demo-simple-select" value={artist} label="Artist" onChange={(e)=>setArtist(e.target.value)}>
              <MenuItem value={""}>Select Artist</MenuItem>
              {artists.length > 0 && artists.map((artist, i)=>{
                return  <MenuItem key={i} value={artist._id}>{artist?.name}</MenuItem>
              })}
            </Select>
          </FormControl>
          {applied.length > 0 &&
          
          <FormControl fullWidth sx={{my:1}}>
            {console.log(applied)}
          <InputLabel id="demo-simple-select-label">Applied</InputLabel>
            
              <Select  labelId="demo-simple-select-label" id="demo-simple-select"  label="Applied" onChange={(e)=>setArtist(e.target.value)}>
                <MenuItem value={""}>Select Artist</MenuItem>
                {applied.map((artist, i)=>{
                let name = artists.filter(musician=>musician._id === artist)
                  return  <MenuItem key={i} value={artist}>{name[0]?.name}</MenuItem>
                })}
            </Select>
          </FormControl>
          }

          <TextField variant='outlined' fullWidth sx={{my:1}} label='Artist Fee' value={artistFee} onChange={(e)=>setArtistFee(e.target.value)} />
          <TextField variant='outlined' fullWidth sx={{my:1}} label='Venue Fee' value={venueFee} onChange={(e)=>setVenueFee(e.target.value)} />
          <TextField variant='outlined' fullWidth sx={{my:1}} multiline rows={2} label='Booking Notes' value={notes} onChange={(e)=>setNotes(e.target.value)} />
          
          <label>From</label>
            <input type='time' style={{height: '40px', width: '100px', margin: '0 5px', padding: '5px'}} value={from} onChange={(e)=>setFrom(e.target.value)}/>
            <label>To</label>
            <input type='time' style={{height: '40px', width: '100px', margin: '0 5px', padding: '5px'}} value={to} onChange={(e)=>setTo(e.target.value)}/>
            <button onClick={addSet} style={{backgroundColor: '#395076', color: 'white', border: 'unset', padding: '5px 10px'}}>Add</button>
              
            {setTimes.length > 0 && setTimes.map((set, i)=>{
              return(
                <div key={i} style={{display:'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
                  <h4 style={{color: '#395076'}}>Set {i+1}</h4>
                  <p style={{color: '#6e767b'}}>{set.from} - {set.to}</p>
                  <DeleteForeverIcon color="error" onClick={()=>removeSet(set)}/>
                </div>
              ) 
            })}
            
          
          <Paper sx={{display: 'flex', justifyContent: 'center',flexWrap: 'wrap', listStyle: 'none', p: 0.5, mb: 2,}} component="ul">
            {equipment.length > 0 && equipment.map((data) => {
              let icon;
              return (
                <ListItem key={data.key}>
                  <Chip icon={icon} label={data.label} onDelete={handleChipDelete(data)} />
                </ListItem>
              );
            })}
                  <input onKeyDown={addItem} value={newItem} onChange={(e)=>setNewItem(e.target.value)}/>
            </Paper>
          <Button variant='contained' sx={{mx:1, backgroundColor:'#395076'}} onClick={checkArtist}>Update</Button>

          <Button variant='contained' sx={{mx:1}} color='error' onClick={deleteEvent}>Delete</Button>

        </Box>
      </Modal>
    </div>
  )
}

const ListItem = styled('li')(({ theme }) => ({
  margin: theme.spacing(0.5),
}));


const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  maxWidth: '95%',
  maxHeight: '80%',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  textAlign:'center',
  overflow:'scroll',
};
