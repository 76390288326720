import React, { useState, useEffect} from 'react'
import { Link } from 'react-router-dom'
import './Dashboard.css'
import { useAuth} from '../Auth/AuthContext'
import { useOutletContext } from "react-router-dom";
import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid' // a plugin!
import axios from 'axios'
import Calendar from './Calendar'

export default function Dashboard() {

  let { currentUser } = useAuth()

  const [tab, setTab] = useState(1)

  const { setActive, navOpen } = useOutletContext();


  return (
    <div className={navOpen ? 'outlet' : 'outlet-expanded'}>

      <div id='business-dash-content'>
         {/* <p style={{fontFamily:'Satoshi', fontSize: 32, letterSpacing: -0.5, color: '#272838'}}>Welcome, <span>{currentUser.displayName}</span></p>
          <p style={{fontFamily:'Inter', fontSize: 18, letterSpacing: -0.5, color: 'grey', margin: '1rem 0'}}>Manage and view calendar bookings</p>

          <div id='business-dash-tabs' >
            <div className={tab === 1 ? 'tab-active' : 'business-dash-tab'} onClick={() => setTab(1)}>
              <p className={tab === 1 ? 'tab-txt-active' : 'tab-txt'}>Calendar</p>
            </div>
            <div className='business-dash-tab' onClick={() => setTab(2)}>
              <div className={tab === 2 ? 'tab-active' : 'business-dash-tab'}>
                <p className={tab === 2 ? 'tab-txt-active' : 'tab-txt'}>Map</p>
              </div>
            </div>
            
            <div className='business-dash-tab' onClick={() => setTab(3)}>
              <div className={tab === 3 ? 'tab-active' : 'business-dash-tab'}>
                <p className={tab === 3 ? 'tab-txt-active' : 'tab-txt'}>Popular Gigs</p>
                <div className='tab-square'>
                  <p>0</p>
                </div>
              </div>
            </div>

            <div className='business-dash-tab' onClick={() => setTab(4)}>
              <div className={tab === 4 ? 'tab-active' : 'business-dash-tab'}>
                <p className={tab === 4 ? 'tab-txt-active' : 'tab-txt'}>Top Musicians</p>
                <div className='tab-square'>
                  <p>0</p>
                </div>
              </div>
            </div>
           
          </div>*/}

      {tab ===1 && 
            <div id='dash-calendar'>
           

                <Calendar />
            
            </div>
           }
      

        {tab === 3 && 
        <div id='popular-jobs-container'>
          <div style={{width: '100%', height: '100%', display:'flex', justifyContent:'center', alignItems:'center'}}>
            <p>No Classes Added</p>
          </div>
        </div>
        }

   
            {tab === 4 && 
          <>
            <div id='top-freelance-scroller'>
            <div style={{width: '100%', height: '100%', display:'flex', justifyContent:'center', alignItems:'center'}}>
            <p>No Musicians Added</p>
            </div>
        
            
            </div>
            <Link to={'/teachers'}>
              <button  id='more-candidates-btn'>
                See More Musicians
              </button>
            </Link>
          </>
          }

      </div>
    </div>
  )
}
